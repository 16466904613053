import service from './axiosConfig';

export const saveLifeHistory = pandaLifeHistory => {
    return service.post('/pandas/v1/pandaLifeHistory', pandaLifeHistory);
};

export const updateLifeHistory = pandaLifeHistory => {
    return service.put('/pandas/v1/pandaLifeHistory', pandaLifeHistory);
};

export const getPandaLifeHistoryById = (lifeId) => {
    return service.get(`/pandas/v1/pandaLifeHistory/${lifeId}`);
};

export const getPandaLifeHistoryByPage = (pandaId,pageNum, pageSize) => {
    return service.get(`/pandas/v1/pandaLifeHistory/page/${pandaId}/${pageNum}/${pageSize}`);
};

export const getAllLifeHistories = () => {
    return service.get(`/pandas/v1/approvedPandaLifeHistories/total`);
};

import {getUserLogin} from "@/api/login";
import {createStore} from "vuex";

export default createStore({
    state: {
        isLoggedIn: false,
        userData: null, // 存储用户数据
    },
    mutations: {
        setLoginState(state, status) {
            state.isLoggedIn = status;
        },
        setUserData(state, data) {
            state.userData = data;
        }
    },
    actions: {
        async login({ commit }, credentials) {
            try {
                const response = await getUserLogin(credentials.username,credentials.password);
                if (response.data.status === 200) {
                    commit('setLoginState', true);
                    commit('userId', credentials.username);
                    // 可以将token存储在localStorage或cookies中以便后续请求使用
                    localStorage.setItem('token', response.data.data.token);
                    localStorage.setItem('user_name', response.data.data.username);
                    localStorage.setItem('userId', response.data.data.id);
                    console.log(response.data.data)
                    return { success: true };
                } else {
                    // 处理非200状态码的情况，返回错误消息
                    return { success: false, message: response.data.message };
                }
            } catch (error) {
                // 捕获并返回后台返回的错误信息
                if (error.response && error.response.data && error.response.data.message) {
                    return { success: false, message: error.response.data.message };
                } else {
                    return { success: false, message: "登录失败，请重试。" };
                }
            }
        }
    }
});

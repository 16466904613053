<template>
  <div class="panda-stats-container mx-auto p-4">
    <div class="text-center" :style="responsivePadding">
      <h1 class="text-4xl md:text-3xl sm:text-2xl font-bold mt-2 mb-6">欢迎来到生活记录平台</h1>
      <p class="text-lg md:text-base sm:text-sm mb-6">这里是关于生活记录的一切信息</p>
      <div class="aaa-router">
        <router-link to="/query-approved/:pageNum/:pageSize" class="explore-btn">探索记录档案</router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

// 响应式内边距
const responsivePadding = computed(() => {
  const width = window.innerWidth;
  if (width < 640) { // sm breakpoint
    return '0 5% 50% 5%'; // 更大的底部内边距
  } else if (width < 768) { // md breakpoint
    return '0 5% 35% 5%'; // 中等的底部内边距
  } else {
    return '0 5% 30% 55%'; // 默认的内边距
  }
});
</script>

<style scoped>
.panda-stats-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.explore-btn {
  background-color: #4CAF50; /* 绿色按钮 */
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  font-weight: bold;
  margin-top: 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.explore-btn:hover {
  background-color: #367C39; /* 深绿色 */
}

@media (max-width: 640px) { /* sm breakpoint */
  .panda-stats-container {
    padding: 10% 5%; /* 小屏幕上的内边距 */
  }
  .explore-btn {
    width: 90%; /* 按钮宽度 */
    margin: 0 auto; /* 按钮居中 */
  }
}

@media (max-width: 768px) { /* md breakpoint */
  .panda-stats-container {
    padding: 15% 5%; /* 中等屏幕上的内边距 */
  }
  .text-lg{
    color: white;
    text-align: center;
  }
  .text-4xl{
    color: white;
    text-align: center;
  }
  .aaa-router {
    text-align: center;
  }
}
</style>

<template>
  <div>
    <!-- 查询表单 -->
    <div style="margin: 15px 0 -10px 70px">
      <el-form :inline="true">
        <el-form-item label="熊猫姓名" prop="pandaId">
          <panda-selector
              v-model="pandaId"
              @update:pandaId="handlePandaSelection"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="fetchDataById">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button v-if="this.authForUpdateStatus === '1'" type="success" @click="addPandaRelationships">新增关系</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 结果表格 -->
    <div style="margin: 0 0 0 -14px">
      <el-table :data="relationsData" style="width: 100%">
        <el-table-column prop="id" label="关系ID" header-align="center" align="center" min-width="10%"></el-table-column>
        <el-table-column v-if="false" prop="pandaId" label="大熊猫ID" header-align="center" align="center"></el-table-column>
        <el-table-column prop="pandaName" label="熊猫姓名" header-align="center" align="center" min-width="10%"></el-table-column>
        <el-table-column header-align="center" align="center"  label="关系" prop="relationType" min-width="10%">
          <template #default="scope">
            {{ translateRelationType(scope.row.relationType) }}
          </template>
        </el-table-column>
        <el-table-column v-if="false" prop="relativePandaId" label="亲属大熊猫ID" header-align="center" align="center"></el-table-column>
        <el-table-column prop="relativePandaName" label="亲属姓名" header-align="center" align="center" min-width="10%"></el-table-column>
        <el-table-column header-align="center" align="center" label="审核状态" min-width="10%">
          <template #default="scope">
            {{ translateAuditStatus(scope.row.auditStatus) }}
          </template>
        </el-table-column>
        <!-- 操作列 -->
        <el-table-column header-align="center" align="center" label="操作" min-width="14%">
          <template #default="scope">
            <el-button v-if="this.authForUpdateStatus === '1'" @click="updateRelativePanda(scope.row)" type="primary" size="small">更新</el-button>
            <el-button v-if="scope.row.authForAuditStatus === '1'" @click="auditRelativePanda(scope.row)" type="success" size="small">审核</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display: flex; justify-content: center;">
      <!-- 分页组件 -->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalRelations">
      </el-pagination>
    </div>
  </div>
  <!-- 审核部分 -->
  <div>
    <el-dialog
        class="custom-dialog-height"
        title="审核熊猫关系信息"
        v-model="showAuditDialog"
        width="35%"
        @close="resetAuditDialog">
      <el-form>
        <el-form-item label="审核意见">
          <el-input type="textarea" v-model="this.auditRecords.comments" placeholder="请输入审核意见"></el-input>
        </el-form-item>
      </el-form>
      <span class="dialog-footer">
        <el-button @click="resetAuditDialog">取消</el-button>
        <el-button type="danger" @click="submitAudit('2')">不通过</el-button>
        <el-button type="success" @click="submitAudit('1')">通过</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {getPandaRelationshipsByPage, getPandaRelationshipsByPandaIdPage} from "@/api/pandaRelationships";
import PandaSelector from "@/components/PandaSelector.vue";
import {saveAuditRecords} from "@/api/auditRecords";

export default {
  components: {
    PandaSelector
  },
  data() {
    return {
      authForUpdateStatus: '0',
      pandaId: null,
      searchName: '', // 查询条件
      relationsData: [], // 表格数据
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页条数
      totalRelations: 0, // 总条数
      showRecordType: false,
      showAuditDialog: false, // 控制审核弹窗显示的变量
      auditRecords: { //审核使用的对象
        recordId: null,
        recordType: '',
        auditResult: '',
        comments: '',
        auditTasksIdArr: [],
      },
    };
  },
  methods: {
    // 获取数据的方法
    async fetchData() {
      try {
        const { data } = await getPandaRelationshipsByPage(this.currentPage, this.pageSize);
        console.log(data)
        this.relationsData = data.data.data.records;
        this.totalRelations = data.data.data.total;
        this.authForUpdateStatus = data.data.authForUpdateStatus;
      } catch (error) {
        console.log(error)
      }
    },
    async fetchDataById() {
      if (this.pandaId === null) {
        const { data } = await getPandaRelationshipsByPage(this.currentPage, this.pageSize);
        console.log(data)
        if (data.status === 200) {
          this.relationsData = data.data.data.records;
          this.totalRelations = data.data.data.total;
        }
      } else {
        const { data } = await getPandaRelationshipsByPandaIdPage(this.pandaId,this.currentPage, this.pageSize);
        console.log(data)
        if (data.status === 200) {
          this.relationsData = data.data.data.records;
          this.totalRelations = data.data.data.total;
        }
      }
      this.pandaId = null;
    },
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.fetchData();
    },
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.fetchData();
    },
    translateRelationType(auditStatus) {
      const statusMap = {
        '0': '父亲',
        '1': '母亲',
        '2': '儿子',
        '3': '女儿',
        '4': '哥哥',
        '5': '姐姐',
        '6': '弟弟',
        '7': '妹妹',
        '8': '配偶',
        '9': '室友',
        '10': '好友',
      };
      return statusMap[auditStatus] || '未知';
    },
    translateAuditStatus(auditStatus) {
      const statusMap = {
        '0': '审核中',
        '1': '通过',
        '2': '未通过',
      };
      return statusMap[auditStatus] || '未知状态';
    },
    // 重置弹窗和审核意见
    resetAuditDialog() {
      this.showAuditDialog = false;
      this.auditRecords.comments = '';
      this.auditRecords.recordId = '';
    },
    auditRelativePanda(row) {
      console.log(row)
      this.showAuditDialog = true;
      this.auditRecords.comments = '';
      this.auditRecords.recordId = row.id;
    },
    // 提交审核结果
    async submitAudit(result) {
      console.log(`审核结果：${result}，审核意见：${this.auditRecords.comments}`);
      if (this.auditRecords.comments === '') {
        this.$message.error("审核意见不能为空")
        return;
      }
      this.auditRecords.recordType = '3';
      this.auditRecords.auditResult = result;
      console.log(this.auditRecords)
      let response;
      try {
        response = await saveAuditRecords(this.auditRecords);
        console.log(response.data);
        // 根据需要处理响应
        this.$message.success(response.data.message);
      } catch (error) {
        console.log(error)
        if (error.response && error.response.status === 400) {
          this.$message.error(error.response.data.message);
        } else {
          this.$message.error('审核失败，请重试。');
        }
      }
      this.resetAuditDialog(); // 审核完成后重置弹窗
      await this.fetchData();
    },
    handlePandaSelection(value) {
      // 当选择框的值更新时，更新绑定的熊猫ID
      this.pandaId = value;
    },
    addPandaRelationships() {
      //打开新增亲属关系页面
      this.$router.push({
        name: 'CreatePandaRelationships',
      });
    },
    updateRelativePanda(row) {
      this.$router.push({ name: 'UpdatePandaRelationships',
        params: {
          relationshipsId: row.id,
        }
      });
    },
  },
  mounted() {
    this.fetchData(); // 组件挂载后立即获取数据
  },
};
</script>
<style scoped>
.custom-dialog-height .el-overlay-dialog {
  height: 400px; /* 根据需要调整高度 */
  display: flex;
  flex-direction: column;
}

.custom-dialog-height .el-dialog__body {
  flex: 1; /* 使内容区域填充可用空间 */
  overflow-y: auto; /* 如果内容超出高度，显示滚动条 */
}
.dialog-footer {
  display: flex;
  justify-content: space-around; /* 均匀分布按钮，并在两端对齐 */
  padding: 10px; /* 根据需要调整内边距 */
}

.custom-dialog-height {
  /* 定义对话框的默认高度或其他样式 */
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

/* 移动端样式适配 */
@media (max-width: 480px) {
  .custom-dialog-height {
    width: 90% !important; /* 移动端对话框宽度调整为屏幕宽度的90% */
  }

  .el-dialog__header {
    padding: 12px 20px; /* 对话框头部内边距调整 */
  }

  .el-dialog {
    margin-top: 10vh; /* 对话框顶部外边距调整，根据需要修改 */
  }

  .el-form-item__label {
    white-space: nowrap; /* 保持标签不换行 */
    flex-basis: auto; /* 允许标签宽度自适应内容 */
    margin-right: 10px; /* 保持标签和输入框之间的间隔 */
  }

  .el-form-item {
    margin-bottom: 12px; /* 增加表单项的垂直间隔 */
  }

  .dialog-footer {
    flex-direction: column-reverse; /* 将按钮垂直排列 */
    align-items: center; /* 按钮居中对齐 */
    margin-top: 10px; /* 增加底部间隔 */
  }

  .dialog-footer .el-button {
    margin-bottom: 10px; /* 增加按钮之间的垂直间隔 */
    width: 100%; /* 按钮宽度调整为100% */
  }
}
</style>

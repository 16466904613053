<template>
  <div class="dashboard">
    <div class="panda-overview-section">
      <PandaOverview />
    </div>
    <div class="mate-status-section">
      <MateStatus />
    </div>
    <div class="audit-status-section">
      <AuditStatus />
    </div>
    <div class="life-status-section">
      <LifeHistoryStatus />
    </div>
    <div class="home-section">
      <HelloHome />
    </div>
  </div>
</template>

<script>
import PandaOverview from "@/components/PandaOverview.vue";
import MateStatus from "@/components/MateStatus.vue";
import AuditStatus from "@/components/AuditStatus.vue";
import LifeHistoryStatus from "@/components/LifeHistoryStatus.vue";
import HelloHome from "@/components/HelloHome.vue";

export default {
  components: {
    PandaOverview,
    MateStatus,
    AuditStatus,
    LifeHistoryStatus,
    HelloHome,
  },
};

</script>

<style>
.dashboard {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  width: 100vw;
  background-image: url('../../public/image/dashboardBackground.png');
  min-height: 100%;
  background-size: cover;
}

/* Styles for components */
.panda-overview-section, .mate-status-section, .audit-status-section, .life-status-section, .home-section {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* Adjust grid placement for each section as needed for desktop layout */
.panda-overview-section { grid-column: 3 / span 1; grid-row: 1 / span 1; }
.mate-status-section { grid-column: 4 / span 1; grid-row: 1 / span 1; }
.audit-status-section { grid-column: 3 / span 1; grid-row: 2 / span 1; }
.life-status-section { grid-column: 4 / span 1; grid-row: 2 / span 1; }
.home-section { grid-column: 1 / span 1; grid-row: 1 / span 1; padding: 0 5% 35% 0; }

/* Mobile devices */
@media (max-width: 768px) {
  /* Styles for medium devices here */
}

@media (max-width: 480px) {
  .dashboard {
    grid-template-columns: 1fr; /* One column layout */
    grid-template-rows: repeat(5, 200px); /* Five rows, each 200px high */
  }
  .home-section {
    grid-column: 1 / -1; /* Fill the full width */
    grid-row: 1; /* Place in the fifth row */
  }
  .panda-overview-section,
  .mate-status-section,
  .audit-status-section,
  .life-status-section {
    grid-column: 1 / -1; /* All components fill the full width, adjusted specificity */
    grid-row: auto; /* Auto place each row */
  }

}

</style>

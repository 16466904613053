<template>
  <div class="panda-stats-container" title="审核信息">
    <div class="cylinders-container">
      <div class="cylinder" v-for="(count, index) in auditCounts" :key="index"
           :style="{height: count.height + 'px'}">
        <div class="cylinder-value">{{ count.value }}</div>
        <div class="cylinder-label">{{ count.label }}</div>
      </div>
    </div>
    <div class="base-info">审核信息</div>
  </div>
</template>

<script>
import { ref, onMounted, watchEffect } from 'vue';
import { gsap } from 'gsap';
import { getAuditTotal } from "@/api/auditRecords";

export default {
  setup() {
    const maxHeight = 150; // 定义最大高度
    const auditCounts = ref([]);

    onMounted(async () => {
      try {
        const response = await getAuditTotal();
        if (response && response.data && response.data.data) {
          const { auditTotal, auditPass, auditNotPass } = response.data.data;
          const total = auditTotal + auditPass + auditNotPass || 1;

          auditCounts.value = [
            { label: '待审核数', value: auditTotal, height: total > 0 ? (auditTotal / total) * maxHeight : 0 },
            { label: '审核通过', value: auditPass, height: total > 0 ? (auditPass / total) * maxHeight : 0 },
            { label: '审核不通过', value: auditNotPass, height: total > 0 ? (auditNotPass / total) * maxHeight : 0 },
          ];
        }
      } catch (error) {
        console.error("获取熊猫审核统计数据失败", error);
      }

      watchEffect(() => {
        // 动画效果
        gsap.to('.cylinder', {
          opacity: 1, // 最终透明度
          backgroundColor: '#6e48aa', // 结束时的背景颜色
          duration: 1.5, // 动画持续时间
          ease: "power3.out", // 动画效果
          stagger: {
            each: 0.3, // 每个动画的延迟
            from: "start", // 从第一个元素开始动画
          }
        });
      });
    });

    return { auditCounts };
  },
};
</script>

<style scoped>
.panda-stats-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.cylinders-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 200px; /* 你可以根据实际情况来调整这个高度 */
}

.cylinder {
  background: linear-gradient(to top, #bbb, #eee);
  border-radius: 50px / 10px;
  width: 80px;
  bottom: 0; /* 固定在容器底部 */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin: 0 0;
}
.cylinder-value {
  color: #FFFFFF; /* 白色字体 */
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5); /* 添加文字阴影以提升可读性 */
  font-size: 20px;
  font-weight: bold;
}

.cylinder-label {
  color: #FFFFFF; /* 黑色字体 101112*/
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5); /* 添加文字阴影以提升可读性 */
  margin-top: 10px;
}

.base-info {
  color: #FFFFFF; /* 白色字体 */
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5); /* 添加文字阴影以提升可读性 */
  margin-top: 0;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
</style>



<template>
  <div class="create-panda-history">
    <el-card class="info-card" :header="'大熊猫新增关系'">
      <el-form ref="pandaHistoryForm" label-width="400px" class="panda-history-form">
        <el-table v-model="relations" :data="relations" style="width: 100%">
          <el-table-column label="熊猫姓名" prop="pandaId">
            <template #default="scope">
              <panda-selector :value="scope.row.pandaId" @update:pandaId="val => scope.row.pandaId = val"></panda-selector>
            </template>
          </el-table-column>
          <el-table-column label="关系类型" prop="relationType">
            <template #default="scope">
              <!-- 这里添加选择关系类型的下拉框 -->
              <el-select v-model="scope.row.relationType" placeholder="请选择">
                <el-option label="父亲" value="0"></el-option>
                <el-option label="母亲" value="1"></el-option>
                <el-option label="儿子" value="2"></el-option>
                <el-option label="女儿" value="3"></el-option>
                <el-option label="哥哥" value="4"></el-option>
                <el-option label="姐姐" value="5"></el-option>
                <el-option label="弟弟" value="6"></el-option>
                <el-option label="妹妹" value="7"></el-option>
                <el-option label="配偶" value="8"></el-option>
                <el-option label="室友" value="9"></el-option>
                <el-option label="好友" value="10"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="亲属熊猫姓名" prop="relativePandaId">
            <template #default="scope">
              <panda-selector :value="scope.row.relativePandaId" @update:pandaId="val => scope.row.relativePandaId = val"></panda-selector>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button @click="deleteRelation(scope.$index)" type="danger" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="buttons-container">
          <el-button @click="addRelation" type="primary">添加</el-button>
          <div class="spacer"></div>
          <el-button @click="saveRelation" type="success">提交</el-button>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import PandaSelector from '@/components/PandaSelector.vue';
import {savePandaRelationships} from "@/api/pandaRelationships";
export default {
  components: {
    PandaSelector
  },
  data() {
    return {
      pandaId: null, // 选中的熊猫ID
      relations: [] // 关系数组
    };
  },
  methods: {
    addRelation() {
      // 向relations数组添加新关系
      this.relations.push({ pandaId: '', relationType: '', relativePandaId: '' });
    },
    deleteRelation(index) {
      // 从relations数组删除指定关系
      this.relations.splice(index, 1);
    },
    async saveRelation(){
      this.relations.forEach(pa => {
        console.log(pa)
      })
      let response;
      try {
        response = response = await savePandaRelationships(this.relations);
        console.log(response.data);
        // 根据需要处理响应
        // this.$message.success(response.data.message);
        //打开新增亲属关系页面
        this.$router.push({
          name: 'QueryPandaRelationships',
          params: {
            pageNum: 1,
            pageSize: 10
          }
        });

      } catch (error) {
        console.log(error)
        if (error.response && error.response.status === 400) {
          this.$message.error(error.response.data.message);
        } else {
          this.$message.error('提交失败，请重试。');
        }
      }
    }
  }
};
</script>

<style scoped>
.create-panda-history .info-card {
  margin-bottom: 20px;
}

.panda-history-form {
  width: 600px;
  margin: auto;
}

.buttons-container {
  display: flex;
  justify-content: space-evenly; /* 使按钮分布在两端 */
  margin-top: 10px; /* 顶部间距 */
}

.info-card {
  text-align: center;
  justify-content: center;
}

.spacer {
  flex-grow: 0; /* 使占位符元素占据所有可用空间 */
}

/* 移动端样式 */
@media (max-width: 480px) {
  .panda-history-form {
    width: 100%; /* 移动端上占满屏幕宽度 */
    padding: 0 15px; /* 添加一些内边距 */
  }

  .buttons-container {
    flex-direction: column; /* 移动端上按钮垂直排列 */
  }

  .buttons-container > .el-button {
    margin-bottom: 10px; /* 增加按钮之间的间距 */
  }

  .buttons-container .spacer {
    display: none; /* 移动端隐藏占位符 */
  }

  /* 为移动端调整表格显示 */
  .el-table--border .el-table__body-wrapper {
    overflow-x: auto; /* 允许在小屏幕上滚动查看表格 */
  }

  /* 减小表格列的最小宽度，或者通过其他方式调整表格布局，确保内容可见 */
  .el-table-column {
    min-width: 80px; /* 根据需要调整 */
  }
}
</style>


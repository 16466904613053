<template title="审核页面">
  <div class="audit-page">
    <!-- 修改后的熊猫基本信息 -->
    <el-card class="info-card" header="熊猫生活历史信息审核">
      <div><strong>事迹ID：</strong>{{ pandaHistory.id }}</div>
      <div><strong>熊猫ID：</strong>{{ pandaInfo.id }}</div>
      <div><strong>熊猫姓名：</strong>{{ pandaInfo.name }}</div>
      <div><strong>熊猫别名：</strong>{{ pandaInfo.alias }}</div>
      <div><strong>事件日期：</strong>{{ pandaHistory.eventDate }}</div>
      <div><strong>事件类型：</strong>{{ translateEventType(pandaHistory.eventType) }}</div>
      <div><strong>事件地点：</strong>{{ pandaHistory.location }}</div>
      <div><strong>事件描述：</strong>{{ pandaHistory.description }}</div>
      <div><strong>附加备注：</strong>{{ pandaHistory.notes }}</div>
      <div v-if="modificationRecords.length" class="form-footer">
        <el-button type="primary" @click="showDialog">审核</el-button>
      </div>
    </el-card>
    <el-card class="history-card" header="基本信息待审核的变更记录">
      <!-- 当modificationRecords有数据时，显示表格 -->
      <el-table v-if="modificationRecords.length" :data="modificationRecords" style="width: 100%">
        <el-table-column header-align="center" align="center" prop="id" label="ID" width="100"></el-table-column>
        <el-table-column header-align="center" align="center" prop="recordId" label="recordId" width="100" v-if="false"></el-table-column>
        <!-- 通过 v-if="false" 来隐藏列 -->
        <el-table-column header-align="center" align="center" prop="recordType" label="recordType" v-if="showRecordType"></el-table-column>
        <!-- 控制显示的变量 -->
        <el-table-column header-align="center" align="center" prop="updateTime" label="修改日期" width="180"></el-table-column>
        <el-table-column prop="notes" label="修改内容" width="auto"></el-table-column>
        <!-- 设置列宽为自动 -->
      </el-table>
      <!-- 当modificationRecords没有数据时，显示提示信息 -->
      <div v-else class="empty-audit-tasks">
        没有待审核任务
      </div>
    </el-card>
  </div>
  <div>
    <el-dialog
        class="custom-dialog-height"
        title="审核熊猫信息"
        v-model="showAuditDialog"
        width="35%"
        @close="resetAuditDialog">
      <el-form>
        <el-form-item label="审核意见">
          <el-input type="textarea" v-model="this.auditRecords.comments" placeholder="请输入审核意见"></el-input>
        </el-form-item>
      </el-form>
      <span class="dialog-footer">
        <el-button @click="resetAuditDialog">取消</el-button>
        <el-button type="danger" @click="submitAudit('2')">不通过</el-button>
        <el-button type="success" @click="submitAudit('1')">通过</el-button>
      </span>
    </el-dialog>
  </div>

</template>
<script>
import { getPandaById } from '@/api/pandas';
import { getAuditTasksByTwo } from '@/api/auditTasks';
import {ElMessage} from "element-plus";
import {saveAuditRecords} from "@/api/auditRecords";
import {getPandaLifeHistoryById} from "@/api/pandaLifeHistory";
export default {
  data() {
    return {
      pandaId: this.$route.params.pandaId,
      lifeId: this.$route.params.lifeId,
      pandaInfo: {}, // 熊猫基本信息
      modificationRecords: [], // 修改记录
      showRecordType: false,
      showAuditDialog: false, // 控制审核弹窗显示的变量
      auditRecords: { //审核使用的对象
        recordId: null,
        recordType: '',
        auditResult: '',
        comments: '',
        auditTasksIdArr: [],
      },
      pandaHistory: {
        id: '',
        pandaId: '',
        eventDate: '',
        eventType: '',
        description: '',
        location: '',
        notes: '',
      },
    };
  },

  methods: {
    async fetchData() {
      let response;
      try {
        //查询熊猫信息
        response = await getPandaById(this.pandaId);
        this.pandaInfo = response.data.data;
        //查询生活历史信息
        response = await getPandaLifeHistoryById(this.lifeId);
        this.pandaHistory = response.data.data;
      } catch (error) {
        console.log(error)
        if (error.response && error.response.status === 400) {
          ElMessage.error(error.response.data.message)
        } else {
          ElMessage.error('查询失败，请重试。')
        }
      }
      try {
        //查询生活历史变更记录
        response = await getAuditTasksByTwo(this.lifeId,"2");
        this.modificationRecords = response.data.data;
        console.log(response)
      } catch (error) {
        console.log(error)
        if (error.response && error.response.status === 400) {
          ElMessage.error(error.response.data.message)
        } else {
          ElMessage.error('查询失败，请重试。')
        }
      }
    },
    // 重置弹窗和审核意见
    resetAuditDialog() {
      this.showAuditDialog = false;
      this.auditRecords.comments = '';
    },

    // 提交审核结果
    async submitAudit(result) {
      console.log(`审核结果：${result}，审核意见：${this.auditRecords.comments}`);
      if (this.auditRecords.comments === '') {
        this.$message.error("审核意见不能为空")
        return;
      }
      const updatedRecords = this.modificationRecords.map((record) => {
        console.log("recordId" + record.recordId + ";recordType:" + record.recordType)
        if (record.recordId !== null && record.recordType !== '') {
          this.auditRecords.recordId = record.recordId;
          this.auditRecords.recordType = record.recordType;
          return record;
        }
      });
      //提取记录ID
      this.auditRecords.auditTasksIdArr = updatedRecords.map(record => record.id);
      this.auditRecords.auditResult = result;
      console.log(this.auditRecords)
      let response;
      try {
        response = await saveAuditRecords(this.auditRecords);
        console.log(response.data);
        // 根据需要处理响应
        this.$message.success(response.data.message);
        if (response.data.status === 200) {
          //新增成功，跳转到熊猫事迹查询页面
          this.$router.push({
            name: 'PandaLifeHistory', // 假设路由名称为'PandaLifeHistory'
            params: {
              pandaId: this.pandaInfo.id,
            }
          });
        }
      } catch (error) {
        console.log(error)
        if (error.response && error.response.status === 400) {
          this.$message.error(error.response.data.message);
        } else {
          this.$message.error('审核失败，请重试。');
        }
      }
      this.resetAuditDialog(); // 审核完成后重置弹窗
      await this.fetchData();
    },
    showDialog() {
      if (this.modificationRecords.length < 1) {
        ElMessage.error("没有待审核内容");
        return;
      }
      this.showAuditDialog = true;
    },
    translateEventType(status) {
      const statusMap = {
        '0': '成长方面',
        '1': '健康方面',
        '2': '繁殖方面',
        '3': '重大事件',
      };
      return statusMap[status] || '未知状态';
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
<style scoped>
.audit-page {
  .info-card, .history-card {
    margin-bottom: 20px;
  }
}
.dialog-footer {
  display: flex;
  justify-content: space-around; /* 均匀分布按钮，并在两端对齐 */
  padding: 10px; /* 根据需要调整内边距 */
}
.custom-dialog-height .el-overlay-dialog {
  height: 400px; /* 根据需要调整高度 */
  display: flex;
  flex-direction: column;
}

.custom-dialog-height .el-dialog__body {
  flex: 1; /* 使内容区域填充可用空间 */
  overflow-y: auto; /* 如果内容超出高度，显示滚动条 */
}
.form-footer {
  display: flex;
  justify-content: center;
}
/* 覆盖卡片的外边距 */
.el-card {
  margin-bottom: 0 !important; /* 移除卡片之间的间隙 */
}

/* 第一个卡片的样式 */
.info-card {
  border-bottom: none; /* 移除第一个卡片底部的边框 */
}

/* 第二个卡片的样式，如果需要 */
.history-card {
  border-top: none;
  margin-top: 0; /* 微调以确保无缝对齐，取决于边框的实际样式 */
}
.empty-audit-tasks {
  text-align: center;
  padding: 20px;
  color: #999;
}
@media (max-width: 480px) {
  .custom-dialog-height {
    width: 90% !important; /* 移动端对话框宽度调整为屏幕宽度的90% */
  }

  .el-dialog__header {
    padding: 12px 20px; /* 对话框头部内边距调整 */
  }

  .el-dialog {
    margin-top: 10vh; /* 对话框顶部外边距调整，根据需要修改 */
  }

  .el-form-item__label {
    white-space: nowrap; /* 保持标签不换行 */
    flex-basis: auto; /* 允许标签宽度自适应内容 */
    margin-right: 10px; /* 保持标签和输入框之间的间隔 */
  }

  .el-form-item {
    margin-bottom: 12px; /* 增加表单项的垂直间隔 */
  }

  .dialog-footer {
    flex-direction: column-reverse; /* 将按钮垂直排列 */
    align-items: center; /* 按钮居中对齐 */
    margin-top: 10px; /* 增加底部间隔 */
  }

  .dialog-footer .el-button {
    margin-bottom: 10px; /* 增加按钮之间的垂直间隔 */
    width: 100%; /* 按钮宽度调整为100% */
  }
}
</style>

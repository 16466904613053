<template>
  <el-menu
      class="menu-bar"
      mode="horizontal"
      router
  >
    <el-menu-item index="1" :route="{ path: '/' }">首页</el-menu-item>
    <el-sub-menu index="2">
      <template #title>档案查看</template>
      <el-menu-item index="/query-approved/:pageNum/:pageSize" :route="{path: '/query-approved/:pageNum/:pageSize'}">基本档案</el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="3">
      <template #title>档案维护</template>
      <el-menu-item index="/query-panda/:pageNum/:pageSize" :route="{ path: '/query-panda/:pageNum/:pageSize' }">基本档案</el-menu-item>
      <el-menu-item index="/query-panda-relationships/:pageNum/:pageSize" :route="{ path: '/query-panda-relationships/:pageNum/:pageSize' }">关系档案</el-menu-item>
      <!-- 登录和退出按钮 -->
      <el-menu-item index="login" @click="handleLogin">后台管理</el-menu-item>
      <el-menu-item index="logout" @click="handleLogout">结束管理</el-menu-item>
    </el-sub-menu>
  </el-menu>
  <router-view></router-view>
  <!-- 页面访问量计数器 -->
  <PageViewCounter />
</template>
<script>
import PageViewCounter from "@/components/PageViewCounter.vue";

export default {
  components: {
    PageViewCounter
  },
  data() {
    return {
      activeIndex: '1', // 默认激活的菜单项
    };
  },
  methods: {
    handleLogin() {
      this.$router.push({ path: '/login' });
    },
    handleLogout() {
      // 清空登录状态的逻辑
      console.log("用户已退出");
      // 重置登录状态，例如使用 Vuex 或其他状态管理工具
      // this.$store.commit('logout');
      localStorage.removeItem('token');
      localStorage.removeItem('user_name');
      localStorage.removeItem('userId');
      // 跳转到首页或登录页面
      this.$router.push({ path: '/' });
    }
  }
}
</script>

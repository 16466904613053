<template>
  <div class="panda-profile">
    <div class="header-info">
      <h1>{{ panda.name }}</h1>
      <p>性别：{{ translateSex(panda.sex) }} | 年龄：{{ panda.age }}</p>
    </div>
    <el-tabs v-model="activeTab" @tab-click="handleTabClick">
      <el-tab-pane label="基本信息" name="基本信息">
        <div class="basic-info">
          <p>出生日期：{{ panda.birthDate }}</p>
          <p>所在地：{{ panda.location }}</p>
          <p>健康状况：{{ panda.healthStatus }}</p>
        </div>
      </el-tab-pane>
      <el-tab-pane label="生活历史" name="生活历史">
        <el-table :data="panda.history" style="width: 100%">
          <el-table-column prop="eventDate" label="事件日期" width="180"></el-table-column>
          <el-table-column prop="eventType" label="事件类型" width="180">
            <template #default="scope">
              {{ translateEventType(scope.row.eventType) }}
            </template>
          </el-table-column>
          <el-table-column prop="location" label="事件地点" width="180"></el-table-column>
          <el-table-column prop="description" label="事件描述" width="180"></el-table-column>
          <el-table-column prop="notes" label="附加备注" width="180"></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="亲属关系" name="亲属关系">
        <FamilyTree :pandaFamilyTreeVo="panda.pandaFamilyTreeVo"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import FamilyTree from "@/components/FamilyTree.vue";
import { getApprovedPandasByPandaId } from "@/api/approvedPandas";

export default {
  components: {
    FamilyTree
  },
  data() {
    return {
      isLoading: true,
      pandaId: this.$route.params.pandaId,
      panda: {
        name: '',
        sex: '',
        age: 0,
        birthDate: '',
        location: '',
        healthStatus: '',
        history: [],
        pandaFamilyTreeVo: {}
      },
      activeTab: '基本信息'
    };
  },
  methods: {
    async fetchPandaDetails() {
      this.isLoading = true;
      try {
        const response = await getApprovedPandasByPandaId(this.pandaId);
        this.panda = response.data.data; // 假设API返回整个熊猫数据，包括历史和亲属关系
        // 确保 relations 是响应式的
        this.panda.pandaFamilyTreeVo = response.data.data.pandaFamilyTreeVo || {};
        this.isLoading = false;
      } catch (error) {
        console.error('获取熊猫信息失败:', error);
        this.isLoading = false;
      }
    },
    translateSex(sex) {
      return sex === 'M' ? '雄性' : sex === 'F' ? '雌性' : '未知';
    },
    translateEventType(status) {
      const statusMap = {
        '0': '成长方面',
        '1': '健康方面',
        '2': '繁殖方面',
        '3': '重大事件',
      };
      return statusMap[status] || '未知状态';
    },
    handleTabClick() {
      // 可以在这里实现特定的点击事件逻辑
    },
  },
  watch: {
    // 监视 'panda.pandaFamilyTreeVo' 的变化
    'panda.pandaFamilyTreeVo': {
      deep: true,
      handler(newVal) {
        console.log('pandaFamilyTreeVo updated:', newVal);
      }
    }
  },
  created() {
    this.fetchPandaDetails();
  }
}
</script>

<style scoped>
.panda-profile .header-info h1 {
  margin: 0;
  color: #333;
}

.panda-profile .header-info p {
  margin: 5px 0;
  color: #666;
}

.panda-profile .el-tabs {
  margin-top: 20px;
}
</style>

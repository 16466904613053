<template>
  <el-form @submit.prevent="submitForm" label-width="auto" class="create-panda-form">
    <el-form-item label="姓名:" required>
      <el-input v-model="panda.name" placeholder="请输入熊猫姓名"></el-input>
    </el-form-item>
    <el-form-item label="别名:" required>
      <el-input v-model="panda.alias" placeholder="请输入熊猫别名"></el-input>
    </el-form-item>
    <el-form-item label="性别:" required>
      <el-select v-model="panda.sex" placeholder="请选择">
        <el-option label="未知" value="unknown"></el-option>
        <el-option label="雄性" value="M"></el-option>
        <el-option label="雌性" value="F"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="所在地:" required>
      <el-input v-model="panda.location" placeholder="请输入熊猫所在地"></el-input>
    </el-form-item>
    <el-form-item label="健康状态:" required>
      <el-input v-model="panda.healthStatus" placeholder="请输入健康状态"></el-input>
    </el-form-item>
    <el-form-item label="出生日期:" required>
      <el-date-picker v-model="panda.birthDate" type="date" format="YYYY-MM-DD" value-format="YYYY-MM-DD" placeholder="选择日期"></el-date-picker>
    </el-form-item>
    <el-form-item label="死亡日期:">
      <el-date-picker v-model="panda.deathDate" type="date" format="YYYY-MM-DD" value-format="YYYY-MM-DD" placeholder="可不选"></el-date-picker>
    </el-form-item>
    <div class="form-footer">
      <el-button type="primary" native-type="submit">提交</el-button>
    </div>
  </el-form>
</template>

<script>
import { createPanda } from '@/api/pandas';
export default {
  name: 'CreatePanda',
  data() {
    return {
      panda: {
        name: '',
        alias: '',
        age: '',
        location: '',
        sex: '',
        healthStatus: '',
        birthDate: '',
        deathDate: '',
        auditStatus: '',
        authForAuditStatus: '',
      },
    };
  },
  methods: {
    async submitForm() {
      let response;
      try {
        response = await createPanda(this.panda);
        console.log(response.data.message);
        // 根据需要处理响应
        this.$message.success(response.data.message);
        //新增成功，跳转到熊猫档案信息页面
        this.$router.push({
          name: 'QueryPandas' ,
          params: {
            pageNum: 1,
            pageSize: 10.
          }
        });
      } catch (error) {
        console.log(error)
        if (error.response && error.response.status === 400) {
          this.$message.error(error.response.data.message);
        } else {
          this.$message.error('提交失败，请重试。');
        }
      }
    },
  },
};
</script>

<style scoped>
.create-panda-form {
  max-width: 600px;
  margin: 20px auto;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}


/* 移动端样式 */
@media (max-width: 480px) {
  .create-panda-form {
    margin: 10px;
  }

  .el-form-item__label {
    flex-basis: auto; /* 允许标签自适应宽度 */
    text-align: left; /* 标签左对齐 */
  }

  .el-form-item {
    margin-bottom: 10px; /* 增加表单项间距 */
  }

  .date-picker-row {
    flex-direction: column; /* 日期选择器垂直排列 */
  }

  .date-picker-item {
    margin-right: 0; /* 移除日期选择项间距 */
    margin-bottom: 10px; /* 增加垂直间距 */
  }

  .date-picker-item:last-child {
    margin-bottom: 0; /* 最后一个日期选择项无底部间距 */
  }

  .form-footer {
    justify-content: center; /* 提交按钮居中 */
  }
}
</style>

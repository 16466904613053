<template>
  <div class="login-container">
    <el-form @submit.prevent="handleLogin">
      <el-form-item label="用户名">
        <el-input v-model="loginForm.username" placeholder="请输入用户名"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input type="password" v-model="loginForm.password" placeholder="请输入密码"></el-input>
      </el-form-item>
      <el-button type="primary" native-type="submit">登录</el-button>
    </el-form>
  </div>
</template>

<script>

export default {
  data() {
    return {
      loginForm: {
        username: '',
        password: '',
      },
    };
  },
  methods: {
    async handleLogin() {
      const { success, message } = await this.$store.dispatch('login', this.loginForm);
      if (success) {
        this.$router.push({ path: '/query-panda/:pageNum/:pageSize' });
      } else {
        this.$message.error(message); // 使用Element Plus的$message服务显示错误消息
      }
    }
  },
};
</script>
<style scoped>
.login-container {
  max-width: 400px; /* 设置最大宽度，确保输入框不会太大 */
  margin: 50px auto; /* 居中显示，页面顶部留出一定空间 */
  padding: 50px 20px; /* 上下50px间距，左右20px间距 */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* 可选：添加阴影效果 */
  border-radius: 10px; /* 可选：边框圆角 */
  background: #ffffff; /* 背景颜色 */
}

.el-form-item {
  margin-bottom: 20px; /* 表单项之间的间距 */
  display: flex; /* 使用 flex 布局对齐 label 和 input */
  align-items: center; /* 垂直居中对齐 */
}

.el-form-item label {
  min-width: 70px; /* 设置最小宽度确保对齐 */
}

.el-form-item:last-child {
  margin-bottom: 0; /* 移除最后一个表单项的底部间距 */
}

.el-button {
  float: right; /* 登录按钮向右对齐 */
  margin-right: 30px; /* 右侧留出一定间距 */
}

.el-input, .el-input__inner {
  width: 100%; /* 输入框宽度充满可用空间 */
}
</style>

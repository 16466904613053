<template>
  <div class="panda-life-history">
    <div style="margin: 20px 0 0 40px">
      <el-form inline class="demo-form-inline">
        <el-form-item label="熊猫ID:">
          <el-input v-model="panda.id" placeholder="请输入ID" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="熊猫姓名:">
          <el-input v-model="panda.name" placeholder="请输入姓名" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="熊猫别名:">
          <el-input v-model="panda.alias" placeholder="请输入别名" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-if="this.authForUpdateStatus ==='1'" @click="addLifeHistory" type="primary">新增事迹</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--大屏样式-->
    <el-table :data="lifeHistoryList" style="width: 100%" v-if="!mediaView">
      <el-table-column prop="id" label="ID" min-width="10%" header-align="center" align="center"></el-table-column>
      <el-table-column prop="eventDate" label="事件日期" min-width="10%" header-align="center" align="center"></el-table-column>
      <el-table-column prop="eventType" label="事件类型" min-width="7%" header-align="center" align="center">
        <template #default="scope">
          {{ translateEventType(scope.row.eventType) }}
        </template>
      </el-table-column>
      <el-table-column prop="location" label="事件地点" min-width="10%" header-align="center" align="center"></el-table-column>
      <el-table-column prop="description" label="事件描述" min-width="20%" header-align="center" align="center"></el-table-column>
      <el-table-column prop="notes" label="附加备注" min-width="10%" header-align="center" align="center"></el-table-column>
      <el-table-column prop="auditStatus" label="审核状态" min-width="7%" header-align="center" align="center">
        <template #default="scope">
          {{ translateAuditStatus(scope.row.auditStatus) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" min-width="15%">
        <template #default="scope">
          <div class="table-actions">
            <el-button v-if="this.authForUpdateStatus ==='1'" @click="updatePanda(scope.row)" type="primary" size="small">更新</el-button>
            <el-button v-if="scope.row.authForAuditStatus === '1'" @click="auditPanda(scope.row)" type="success" size="small">审核</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--小屏样式-->
    <el-table :data="lifeHistoryList" style="width: 100%" v-if="mediaView">
      <el-table-column prop="id" label="ID" header-align="center" align="center"></el-table-column>
      <el-table-column prop="eventDate" label="事件日期" header-align="center" align="center"></el-table-column>
      <el-table-column prop="eventType" label="事件类型" header-align="center" align="center">
        <template #default="scope">
          {{ translateEventType(scope.row.eventType) }}
        </template>
      </el-table-column>
      <el-table-column prop="location" label="事件地点" header-align="center" align="center"></el-table-column>
      <el-table-column prop="description" label="事件描述" header-align="center" align="center"></el-table-column>
      <el-table-column prop="notes" label="附加备注" header-align="center" align="center"></el-table-column>
      <el-table-column prop="auditStatus" label="审核状态" header-align="center" align="center">
        <template #default="scope">
          {{ translateAuditStatus(scope.row.auditStatus) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <div class="table-actions">
            <el-button v-if="this.authForUpdateStatus ==='1'" @click="updatePanda(scope.row)" type="primary" size="small">更新</el-button>
            <el-button v-if="scope.row.authForAuditStatus === '1'" @click="auditPanda(scope.row)" type="success" size="small">审核</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: center;">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getPandaLifeHistoryByPage } from '@/api/pandaLifeHistory';
import {getPandaById} from "@/api/pandas";

export default {
  data() {
    return {
      authForUpdateStatus: '0',
      lifeHistoryList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      mediaView: false,
      pandaId: this.$route.params.pandaId, // 从路由获取大熊猫ID
      panda: {
        name: '',
        alias: '',
        age: '',
        location: '',
        sex: '',
        healthStatus: '',
        birthDate: '',
        deathDate: '',
        auditStatus: '',
      },
    };
  },
  methods: {
    async fetchData() {
      try {
        let response = await getPandaLifeHistoryByPage(this.pandaId, this.currentPage, this.pageSize);
        if (response && response.data) {
          this.lifeHistoryList = response.data.data.data.records;
          this.total = response.data.data.data.total;
          this.authForUpdateStatus = response.data.data.authForUpdateStatus;
        }
        response = await getPandaById(this.pandaId);
        console.log(response);
        this.panda = response.data.data
      } catch (error) {
        console.log(error);
      }
    },
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.fetchData();
    },
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.fetchData();
    },
    translateEventType(status) {
      const statusMap = {
        '0': '成长方面',
        '1': '健康方面',
        '2': '繁殖方面',
        '3': '重大事件',
      };
      return statusMap[status] || '未知状态';
    },
    translateAuditStatus(status) {
      const statusMap = {
        '0': '审核中',
        '1': '通过',
        '2': '未通过',
      };
      return statusMap[status] || '未知状态';
    },
    adjustTableColumnWidth() {
      if (window.innerWidth < 480) { // 假设480px以下为移动设备
        this.mediaView = true;
      }
    },
    addLifeHistory() {
      this.$router.push({ name: 'CreatePandaHistory', params: { pandaId: this.pandaId } });
    },
    updatePanda(row) {
      this.$router.push({ name: 'UpdatePandaHistory',
        params: {
        id: row.id,
          pandaId: this.pandaId} });
    },
    auditPanda(row) {
      this.$router.push({ name: 'AuditTasksForHistory',
        params: {
          lifeId: row.id,
          pandaId: this.pandaId} });
    },
  },
  mounted() {
    this.adjustTableColumnWidth();
    window.addEventListener('resize', this.adjustTableColumnWidth);
    this.fetchData();
  },
};
</script>

<style scoped>
.panda-life-history {
  margin: 20px;
}
</style>

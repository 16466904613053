<template>
  <div class="page-view-counter">
    <div>
      访问量：{{ pageViewCount }}
    </div>
    <div>
      <a href="https://beian.mps.gov.cn/#/query/webSearch?code=11010502055026" rel="noreferrer" target="_blank">
        <img :src="require('@/assets/image/备案图标.png')" alt="备案图标" style="height:16px; vertical-align:middle; margin-right: 5px;">
        京公网安备11010502055026
      </a>
      <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2024061322号-1</a>
    </div>
  </div>
</template>


<script>
import { getAccessCount } from "@/api/accessLogs";

export default {
  data() {
    return {
      pageViewCount: 0,
      intervalId: null,
    };
  },
  async created() {
    await this.fetchPageView();
    this.startAutoRefresh();
  },
  beforeUnmount() {
    this.stopAutoRefresh();
  },
  methods: {
    async fetchPageView() {
      try {
        let response = await getAccessCount();
        this.pageViewCount = response.data.data;
      } catch (error) {
        console.error('Error fetching page views:', error);
      }
    },
    startAutoRefresh() {
      this.intervalId = setInterval(this.fetchPageView, 60000);
    },
    stopAutoRefresh() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
  }
}
</script>

<style scoped>
.page-view-counter {
  position: fixed;
  bottom: 10px;
  color: blue;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  z-index: 9998;
}
</style>

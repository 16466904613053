<template>
  <div>
    <el-autocomplete
        v-model="selectedPandaName"
        :fetch-suggestions="debouncedSearchPandas"
        placeholder="请输入熊猫姓名"
        @select="handleSelect"
    ></el-autocomplete>
  </div>
</template>

<script>
import { debounce } from "lodash-es";
import { getPandasByPage } from "@/api/pandas";

export default {
  data() {
    return {
      selectedPandaName: "",
      selectedPandaId: null,
      debouncedSearchPandas: null,
    };
  },
  created() {
    this.debouncedSearchPandas = debounce(this.searchPandas, 3000);
  },
  methods: {
    async searchPandas(queryString, cb) {
      try {
        const response = await getPandasByPage(1, 5, '', queryString, '');
        const suggestions = response.data.data.data.records.map(panda => ({
          value: panda.name,
          id: panda.id
        }));
        cb(suggestions);
      } catch (error) {
        cb([]);
      }
    },
    handleSelect(item) {
      this.selectedPandaName = item.value;//显示到选择框
      this.selectedPandaId = item.id;//显示到选择框
      this.$emit('update:pandaId', item.id);//传递到父组件
    }
  },
};
</script>

import service from './axiosConfig';

export const createPanda = pandaData => {
    return service.post('/pandas/v1/pandas', pandaData);
};

export const updatePanda = pandaData => {
    return service.put('/pandas/v1/pandas', pandaData);
};

export const getPandas = () => {
    return service.get('/pandas/v1/pandas');
};

export const getPandasByPage = (pageNum, pageSize,id,name,alias) => {
    return service.get(`/pandas/v1/pandas/${pageNum}/${pageSize}?id=${id}&name=${name}&alias=${alias}`);
};

export const getPandaById = (pandaId) => {
    return service.get(`/pandas/v1/pandas/${pandaId}`);
};

// 其他与大熊猫相关的API请求...

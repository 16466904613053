<template>
  <!--大屏样式-->
  <div v-if="!mediaView" class="panda-list">
    <el-input v-model="searchText" placeholder="按姓名模糊搜索" style="max-width: 20%" class="search-input" @input="handleSearch" />
    <el-table :data="filteredPandas" style="width: 100%" @sort-change="handleSort" class="panda-table">
      <el-table-column header-align="center" align="center" min-width="10%" prop="name" label="姓名" sortable="custom"></el-table-column>
      <el-table-column header-align="center" align="center" min-width="10%" prop="sex" label="性别">
        <template #default="scope">
          {{ translateSex(scope.row.sex) }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" min-width="10%" prop="age" label="年龄" sortable></el-table-column>
      <el-table-column header-align="center" align="center" min-width="10%" label="操作">
        <template #default="scope">
          <el-button @click="goToPanda(scope.row)" size="small">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: center;">
      <el-pagination
          class="el-pagination"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
  </div>
  <!--小屏样式-->
  <div v-if="mediaView" class="panda-list">
    <el-input v-model="searchText" placeholder="搜索熊猫" style="max-width: 50%" class="search-input" @input="handleSearch" />
    <el-table :data="filteredPandas" style="width: 100%" @sort-change="handleSort" class="panda-table">
      <el-table-column header-align="center" align="center" min-width="20%" prop="name" label="姓名" sortable="custom"></el-table-column>
      <el-table-column header-align="center" align="center" min-width="20%" prop="sex" label="性别">
        <template #default="scope">
          {{ translateSex(scope.row.sex) }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" min-width="20%" prop="age" label="年龄" sortable></el-table-column>
      <el-table-column header-align="center" align="center" min-width="20%" label="操作">
        <template #default="scope">
          <el-button @click="goToPanda(scope.row)" size="small">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: center;">
      <el-pagination
          class="el-pagination"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>


<script>
import {getApprovedPandasByNameForPage, getApprovedPandasByPage} from "@/api/approvedPandas";

export default {
  data() {
    return {
      mediaView: false,
      total: 1,
      pandas: [],
      searchText: '',
      currentPage: 1,
      pageSize: 20,
      sort: { prop: null, order: null } // 初始化无排序
    };
  },
  computed: {
    filteredPandas() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      let result = this.pandas.filter(panda => panda.name.includes(this.searchText));

      // 排序处理
      if (this.sort.prop && this.sort.order) {
        result.sort((a, b) => {
          let modifier = 1;
          if (this.sort.order === 'descending') {
            modifier = -1;
          }
          if (a[this.sort.prop] < b[this.sort.prop]) {
            return -1 * modifier;
          }
          if (a[this.sort.prop] > b[this.sort.prop]) {
            return modifier;
          }
          return 0;
        });
      }

      return result.slice(start, end);
    }
  },
  methods: {
    async fetchPandas() {
      try {
        const response = await getApprovedPandasByPage(this.currentPage, this.pageSize);
        console.log(this.sort)
        this.total = response.data.data.total;
        this.pandas = response.data.data.records;
      } catch (error) {
        console.error(error);
      }
    },
    async handleSearch() {
      this.currentPage = 1;
      try {
        const response = await getApprovedPandasByNameForPage(this.currentPage, this.pageSize,this.searchText);
        console.log(this.sort)
        this.total = response.data.data.total;
        this.pandas = response.data.data.records;
      } catch (error) {
        console.error(error);
      }
    },
    handleSort({ prop, order }) {
      this.sort = { prop, order };
      this.fetchPandas(); // Fetch sorted data from the backend or re-compute frontend sorting
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchPandas();
    },
    goToPanda(panda) {
      this.$router.push({ name: 'PandaProfile', params: { pandaId: panda.id } });
    },
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.fetchPandas();
    },
    translateSex(sex) {
      const sexMap = { M: '雄性', F: '雌性', unknown: '未知' };
      return sexMap[sex] || '未知';
    },
    adjustTableColumnWidth() {
      if (window.innerWidth < 480) { // 假设480px以下为移动设备
        this.mediaView = true;
      }
    }
  },
  mounted() {
    this.adjustTableColumnWidth();
    window.addEventListener('resize', this.adjustTableColumnWidth);
    this.fetchPandas();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.adjustTableColumnWidth);
  },
}
</script>

<style scoped>
.panda-list {
  margin: 20px;
}

.search-input {
  width: 100%;
  margin-bottom: 10px; /* 为搜索框和表格添加间隔 */
}

.panda-table .el-table__header-wrapper,
.panda-table .el-table__body-wrapper {
  min-width: 100%; /* 确保表格可以滚动 */
}

@media (max-width: 768px) {
  .el-pagination {
    font-size: 12px; /* 更小的字体尺寸 */
    padding: 0 10px; /* 减少内边距 */
  }

  .el-button {
    padding: 3px 6px; /* 调整按钮大小以适应小屏幕 */
  }
}

@media (max-width: 480px) {
  .el-pagination {
    width: 100%; /* 确保分页不会超出屏幕宽度 */
    box-sizing: border-box; /* 边框和内边距包含在宽度内 */
    overflow-x: auto; /* 允许横向滚动 */
  }
  .el-pagination .el-select {
    width: 85px !important;
  }
}
</style>





<template>
  <div class="create-panda-history">
    <el-card class="info-card" :header="'大熊猫更新关系'">
      <el-form ref="pandaHistoryForm" label-width="400px" class="panda-history-form">
        <el-table v-model="relations" :data="relations" style="width: 100%">
          <el-table-column label="熊猫姓名" prop="pandaName">
            <template #default="scope">
              <el-input v-model="scope.row.pandaName" placeholder="请输入姓名" :disabled="true"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="关系类型" prop="relationType">
            <template #default="scope">
              <!-- 这里添加选择关系类型的下拉框 -->
              <el-select v-model="scope.row.relationType" placeholder="请选择">
                <el-option label="父亲" value="0"></el-option>
                <el-option label="母亲" value="1"></el-option>
                <el-option label="儿子" value="2"></el-option>
                <el-option label="女儿" value="3"></el-option>
                <el-option label="哥哥" value="4"></el-option>
                <el-option label="姐姐" value="5"></el-option>
                <el-option label="弟弟" value="6"></el-option>
                <el-option label="妹妹" value="7"></el-option>
                <el-option label="配偶" value="8"></el-option>
                <el-option label="室友" value="9"></el-option>
                <el-option label="好友" value="10"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="亲属熊猫姓名" prop="relativePandaId">
            <template #default="scope">
              <panda-selector :value="scope.row.relativePandaId" @update:pandaId="val => scope.row.relativePandaId = val"></panda-selector>
            </template>
          </el-table-column>
        </el-table>
        <div class="buttons-container">
          <div class="spacer"></div>
          <el-button @click="updateRelation" type="success">提交</el-button>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import PandaSelector from '@/components/PandaSelector.vue';
import {getPandaRelationshipsById, updatePandaRelationships} from "@/api/pandaRelationships";
export default {
  components: {
    PandaSelector
  },
  data() {
    return {
      relationshipsId: this.$route.params.relationshipsId, // 选中的熊猫关系ID
      relations: [] // 关系数组
    };
  },
  methods: {
    addRelation() {
      // 向relations数组添加新关系
      this.relations.push({ pandaId: '', relationType: '', relativePandaId: '' });
    },
    deleteRelation(index) {
      // 从relations数组删除指定关系
      this.relations.splice(index, 1);
    },
    async updateRelation(){
      let response;
      try {
        response = response = await updatePandaRelationships(this.relations);
        console.log(response.data);
        // 根据需要处理响应
        // this.$message.success(response.data.message);
        //打开新增亲属关系页面
        this.$router.push({
          name: 'QueryPandaRelationships',
          params: {
            pageNum: 1,
            pageSize: 10
          }
        });

      } catch (error) {
        console.log(error)
        if (error.response && error.response.status === 400) {
          this.$message.error(error.response.data.message);
        } else {
          this.$message.error('更新失败，请重试。');
        }
      }
    },
    async fetchPandaInfo() {
      let response;
      try {
        response = await getPandaRelationshipsById(this.relationshipsId);
        console.log(response);
        this.relations.push(response.data.data)
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.$message.error(error.response.data.message);
        } else {
          this.$message.error('查询失败，请重试。');
        }
      }

    },
  },
  mounted() {
    this.fetchPandaInfo(); // 加载大熊猫的基本信息
  },
};
</script>

<style scoped>
.create-panda-history .info-card {
  margin-bottom: 20px;
}

.panda-history-form {
  width: 600px;
  margin: auto;
}

.buttons-container {
  display: flex;
  justify-content: space-evenly; /* 使按钮分布在两端 */
  margin-top: 10px; /* 顶部间距 */
}

.info-card {
  text-align: center;
  justify-content: center;
}

.spacer {
  flex-grow: 0; /* 使占位符元素占据所有可用空间 */
}

/* 移动端样式适配 */
@media (max-width: 768px) {
  .panda-history-form {
    width: 100%; /* 移动端全屏宽度 */
    padding: 0 20px; /* 增加一些内边距以避免内容紧贴屏幕边缘 */
  }

  .buttons-container {
    flex-direction: column; /* 移动端按钮垂直排列 */
    align-items: center; /* 居中对齐按钮 */
  }

  .buttons-container > .el-button {
    width: 100%; /* 让按钮宽度与容器一致 */
    margin-bottom: 10px; /* 为按钮之间增加垂直间隔 */
  }

  .buttons-container .spacer {
    display: none; /* 移动端不需要占位符 */
  }

  .el-table--border .el-table__body-wrapper {
    overflow-x: auto; /* 允许表格在小屏幕上水平滚动 */
  }

  .el-table .el-table__header-wrapper,
  .el-table .el-table__body-wrapper {
    max-width: 100%; /* 限制表格宽度，使其在小屏幕上不会超出视口 */
  }
}
</style>


import service from './axiosConfig';

export const savePandaRelationships = pandaRelationshipsList => {
    return service.post('/pandas/v1/pandaRelationships', pandaRelationshipsList);
};

export const updatePandaRelationships = pandaRelationshipsList => {
    return service.put('/pandas/v1/pandaRelationships', pandaRelationshipsList);
};

export const getPandaRelationshipsByPage = (pageNum, pageSize) => {
    return service.get(`/pandas/v1/pandaRelationships/all/page/${pageNum}/${pageSize}`);
};


export const getPandaRelationshipsByPandaIdPage = (pandaId, pageNum, pageSize) => {
    return service.get(`/pandas/v1/pandaRelationships/page/${pandaId}/${pageNum}/${pageSize}`);
};

export const getApprovedPandaRelationsTotal = () => {
    return service.get(`/pandas/v1/approvedPandaRelationships/total`);
};

export const getPandaRelationshipsById = (id) => {
    return service.get(`/pandas/v1/pandaRelationships/${id}`);
};

<template>
  <div class="create-panda-history">
    <el-card class="info-card" :header="'大熊猫生活历史'" style="text-align: center">
      <el-form ref="pandaHistoryForm" :model="pandaHistory" label-width="120px" class="panda-history-form">
        <el-form-item label="熊猫ID:">
          <el-input v-model="panda.id" placeholder="请输入ID" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="熊猫姓名:">
          <el-input v-model="panda.name" placeholder="请输入姓名" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="熊猫别名:">
          <el-input v-model="panda.alias" placeholder="请输入别名" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="事件日期:" prop="eventDate" required>
          <el-date-picker v-model="pandaHistory.eventDate" type="date" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="事件类型:" prop="eventType" required>
          <el-select v-model="pandaHistory.eventType" placeholder="请选择事件类型">
            <el-option label="成长方面" value="0"></el-option>
            <el-option label="健康方面" value="1"></el-option>
            <el-option label="繁殖方面" value="2"></el-option>
            <el-option label="重大事件" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="事件地点:" prop="location" required>
          <el-input v-model="pandaHistory.location" placeholder="请输入事件地点"></el-input>
        </el-form-item>
        <el-form-item label="事件描述:" prop="description" required>
          <el-input v-model="pandaHistory.description" type="textarea" placeholder="请输入事件描述"></el-input>
        </el-form-item>
        <el-form-item label="附加备注:" prop="notes">
          <el-input v-model="pandaHistory.notes" placeholder="请输入附加备注"></el-input>
        </el-form-item>
        <div class="form-footer">
          <el-button type="primary" @click="submitForm">提交</el-button>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import {saveLifeHistory} from "@/api/pandaLifeHistory";
import {getPandaById} from "@/api/pandas";

export default {
  data() {
    return {
      pandaHistory: {
        pandaId: this.$route.params.pandaId,
        eventDate: '',
        eventType: '',
        description: '',
        location: '',
        notes: '',
      },
      pandaInfo: {}, // 假设这里将存储大熊猫的基本信息
      panda: {
        name: '',
        alias: '',
        age: '',
        location: '',
        sex: '',
        healthStatus: '',
        birthDate: '',
        deathDate: '',
        auditStatus: '',
      },
    };
  },
  methods: {
    async submitForm() {
      console.log("提交的生活历史记录:", this.pandaHistory);
      let response;
      try {
        response = await saveLifeHistory(this.pandaHistory);
        // 根据需要处理响应
        this.$message.success(response.data.message);
        //新增成功，跳转到生活历史页面
        if (response.data.status === 200) {
          this.$router.push({
            name: 'PandaLifeHistory' ,
            params: {
              pandaId: this.pandaHistory.pandaId,
            }
          });
        }
      } catch (error) {
        console.log(error)
        if (error.response && error.response.status === 400) {
          this.$message.error(error.response.data.message);
        } else {
          this.$message.error('提交失败，请重试。');
        }
      }
    },
    async fetchPandaInfo() {
      const response = await getPandaById(this.pandaHistory.pandaId);
      console.log(response);
      this.panda = response.data.data
    },
  },
  mounted() {
    this.fetchPandaInfo(); // 加载大熊猫的基本信息
  },
};
</script>

<style scoped>
.create-panda-history .info-card {
  margin-bottom: 20px;
}

.panda-history-form {
  width: 600px;
  margin: 25px auto; /* 修改为auto以使其在大屏幕上居中 */
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}

/* 移动端样式调整 */
@media (max-width: 480px) {
  .panda-history-form {
    width: 100%; /* 移动端上宽度调整为100%，以适应屏幕宽度 */
    margin: 10px; /* 减少外边距，适应更紧凑的空间 */
  }

  .create-panda-history .info-card {
    margin-bottom: 10px; /* 移动端减少卡片的外边距 */
  }

  .el-form-item__label {
    flex-basis: 100%; /* 标签占据整行 */
    text-align: left; /* 标签文本左对齐 */
    margin-bottom: 5px; /* 增加标签和输入框之间的间距 */
  }

  .el-form-item {
    display: block; /* 调整表单项为块级元素，确保标签和输入框垂直排列 */
    margin-bottom: 10px; /* 增加表单项之间的间隔 */
  }

  .form-footer {
    justify-content: center; /* 移动端将按钮居中显示 */
  }
}
</style>

import axios from 'axios';

// 创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL, // API的base_url
    timeout: 50000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
    config => {
        const userId = localStorage.getItem('userId'); // 以localStorage为例
        const user_name = localStorage.getItem('user_name'); // 以localStorage为例
        const token = localStorage.getItem('token'); // 以localStorage为例
        console.log("userId" + userId)
        console.log("token" + token)
        if (userId) {
            config.headers['X-User-Id'] = userId;
            config.headers['token'] = token;
            config.headers['user_name'] = user_name;
        } else {
            config.headers['X-User-Id'] = "999999";
        }
        return config;
    },
    error => {
        console.log("执行了")
        return Promise.reject(error);
    }
);

export default service;

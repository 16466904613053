<template title="新增">
  <div>
    <div style="margin: 0 0 0 4%">
      <el-form inline class="demo-form-inline">
        <el-form-item label="ID:">
          <el-input v-model="searchConditions.id" placeholder="请输入ID"></el-input>
        </el-form-item>
        <el-form-item label="姓名:">
          <el-input v-model="searchConditions.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="别名:">
          <el-input v-model="searchConditions.alias" placeholder="请输入别名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="fetchData">查询档案</el-button>
        </el-form-item>
        <el-form-item>
          <el-button v-if="this.authForUpdateStatus === '1'" type="success" @click="addData">新增档案</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--大屏时的样式-->
    <el-table :data="pandasList" border v-if="!mediaView">
      <el-table-column header-align="center" align="center" prop="id" label="ID" min-width="10%"></el-table-column>
      <el-table-column header-align="center" align="center" prop="name" label="姓名" min-width="10%"></el-table-column>
      <el-table-column header-align="center" align="center" prop="alias" label="别名" min-width="10%"></el-table-column>
      <el-table-column header-align="center" align="center" prop="age" label="年龄" min-width="4%"></el-table-column>
      <el-table-column header-align="center" align="center" prop="location" label="所在地" min-width="10%"></el-table-column>
      <!-- 使用scoped slot来自定义性别列的显示 -->
      <el-table-column header-align="center" align="center"  label="性别" prop="sex" min-width="8%">
        <template #default="scope">
          {{ translateSex(scope.row.sex) }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" prop="healthStatus" label="健康状态" min-width="8%"></el-table-column>
      <el-table-column header-align="center" align="center" prop="birthDate" label="出生日期" min-width="7%"></el-table-column>
      <el-table-column header-align="center" align="center" prop="deathDate" label="死亡日期" min-width="7%"></el-table-column>
      <el-table-column header-align="center" align="center" label="审核状态" min-width="8%">
        <template #default="scope">
          {{ translateAuditStatus(scope.row.auditStatus) }}
        </template>
      </el-table-column>
      <!-- 操作列 -->
      <el-table-column header-align="center" align="center" label="操作" min-width="14%">
        <template #default="scope">
          <el-button v-if="this.authForUpdateStatus === '1'" @click="updatePanda(scope.row)" type="primary" size="small">更新</el-button>
          <el-button v-if="scope.row.authForAuditStatus === '1'" @click="auditPanda(scope.row)" type="success" size="small">审核</el-button>
          <el-button @click="viewLifeHistory(scope.row)" type="info" size="small">事迹</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--小屏幕移动端的样式-->
    <el-table :data="pandasList" border v-if="mediaView">
      <el-table-column header-align="center" align="center" prop="id" label="ID"></el-table-column>
      <el-table-column header-align="center" align="center" prop="name" label="姓名"></el-table-column>
      <el-table-column header-align="center" align="center" prop="alias" label="别名"></el-table-column>
      <el-table-column header-align="center" align="center" prop="age" label="年龄"></el-table-column>
      <el-table-column header-align="center" align="center" prop="location" label="所在地"></el-table-column>
      <!-- 使用scoped slot来自定义性别列的显示 -->
      <el-table-column header-align="center" align="center"  label="性别" prop="sex">
        <template #default="scope">
          {{ translateSex(scope.row.sex) }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" prop="healthStatus" label="健康状态" ></el-table-column>
      <el-table-column header-align="center" align="center" prop="birthDate" label="出生日期" ></el-table-column>
      <el-table-column header-align="center" align="center" prop="deathDate" label="死亡日期" ></el-table-column>
      <el-table-column header-align="center" align="center" label="审核状态">
        <template #default="scope">
          {{ translateAuditStatus(scope.row.auditStatus) }}
        </template>
      </el-table-column>
      <!-- 操作列 -->
      <el-table-column header-align="center" align="center" label="操作" >
        <template #default="scope">
          <el-button v-if="this.authForUpdateStatus === '1'" @click="updatePanda(scope.row)" type="primary" size="small">更新</el-button>
          <el-button v-if="scope.row.authForAuditStatus === '1'" @click="auditPanda(scope.row)" type="success" size="small">审核</el-button>
          <el-button @click="viewLifeHistory(scope.row)" type="info" size="small">事迹</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: center;">
      <el-pagination
          class="el-pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getPandasByPage } from '@/api/pandas';

export default {
  data() {
    return {
      authForUpdateStatus: '0',
      pandasList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      mediaView: false,
      searchConditions: {
        id: '',
        name: '',
        alias: '',
      },
    };
  },
  methods: {
    async fetchData() {
      try {
        const { data } = await getPandasByPage(this.currentPage, this.pageSize,this.searchConditions.id,this.searchConditions.name,this.searchConditions.alias);
        this.pandasList = data.data.data.records;
        this.total = data.data.data.total;
        this.authForUpdateStatus = data.data.authForUpdateStatus;
      } catch (error) {
        console.log(error)
      }
    },
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.fetchData();
    },
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.fetchData();
    },
    translateSex(sex) {
      const sexMap = {
        M: '雄性',
        F: '雌性',
        unknown: '未知',
      };
      return sexMap[sex] || '未知';
    },
    updatePanda(row) {
      console.log(row)
      // 传递熊猫对象到更新页面或弹出更新对话框
      this.$router.push({
        name: 'UpdatePanda' ,
        params: {
          pandaId: row.id,
        }
      });
      console.log("更新熊猫信息", row.id);
    },
    translateAuditStatus(auditStatus) {
      const statusMap = {
        '0': '审核中',
        '1': '通过',
        '2': '未通过',
      };
      return statusMap[auditStatus] || '未知状态';
    },
    auditPanda(row) {
      console.log("进入审核", row.id);
      // 示例：跳转到审核页面，假设路由名称为'AuditPanda'，并传递熊猫ID
      this.$router.push({
        name: 'AuditTasksForPanda',
        params: {
          pandaId: row.id,
        }
      });
    },
    viewLifeHistory(row) {
      console.log("查看历史生活信息", row.id);
      this.$router.push({
        name: 'PandaLifeHistory', // 假设路由名称为'PandaLifeHistory'
        params: {
          pandaId: row.id,
        }
      });
    },
    addData() {
      //打开新增页面
      this.$router.push({
        name: 'CreatePanda',
      });
    },
    adjustTableColumnWidth() {
      if (window.innerWidth < 480) { // 假设480px以下为移动设备
        this.mediaView = true;
      }
    }
  },
  mounted() {
    this.adjustTableColumnWidth();
    window.addEventListener('resize', this.adjustTableColumnWidth);
    this.fetchData();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.adjustTableColumnWidth);
  },
};
</script>
<style scoped>
.demo-form-inline{
  margin: 20px 10px -10px 10px;
  font-weight: 600;
}
/* 针对小屏幕设备进行样式调整 */
@media (max-width: 480px) {
  /* 允许水平滚动 */
  .el-table--border .el-table__body-wrapper {
    overflow-x: auto;
  }

  /* 限制表格宽度 */
  .el-table .el-table__header-wrapper,
  .el-table .el-table__body-wrapper {
    max-width: 100vw;
  }

  /* 调整表格列的最小宽度 */
  .el-table--border .el-table__header-wrapper colgroup col,
  .el-table--border .el-table__body-wrapper colgroup col {
    min-width: auto !important;
  }

  /* 分页器样式调整 */
  .el-pagination {
    margin-top: 20px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
  }
}

</style>

import {createRouter, createWebHistory} from 'vue-router';
import CreatePanda from '../views/CreatePanda.vue'
import CreatePandaHistory from '../views/life/CreatePandaHistory.vue'
import QueryPandas from "@/views/QueryPandas.vue";
import UpdatePanda from "@/views/UpdatePanda.vue";
import PandaLifeHistory from "@/views/life/PandaLifeHistory.vue";
import UpdatePandaHistory from "@/views/life/UpdatePandaHistory.vue";
import AuditTasksForPanda from "@/views/audit/AuditTasksForPanda.vue";
import AuditTasksForHistory from "@/views/audit/AuditTasksForHistory.vue";
import CreatePandaRelationships from "@/views/relations/CreatePandaRelationships.vue";
import QueryPandaRelationships from "@/views/relations/QueryPandaRelationships.vue";
import PersonnelLogin from "@/views/login/PersonnelLogin.vue";
import {getCheckUser} from "@/api/login";
import MainInterface from "@/views/HomePage.vue";
import mainInterface from "@/views/HomePage.vue";
import UpdatePandaRelationships from "@/views/relations/UpdatePandaRelationships.vue";
import ApprovedPandas from "@/views/approved/ApprovedPandas.vue";
import PandaProfile from "@/views/approved/PandaProfile.vue";

const routes = [
  {
    path: '/',
    name: 'mainInterface',
    component: mainInterface,
    meta: { requiresAuth: false },
  },
  {
    path: '/mainInterface',
    name: 'MainInterface',
    component: MainInterface,
    meta: { requiresAuth: false },
  },
  {
    path: '/login',
    name: 'PersonnelLogin',
    component: PersonnelLogin,
  },
  {
    path: '/lifeHistory/:pandaId',
    name: 'PandaLifeHistory',
    component: PandaLifeHistory,
    props: true,
    meta: { requiresAuth: false },
  },
  {
    path: '/panda-history/:pandaId',
    name: 'CreatePandaHistory',
    component: CreatePandaHistory,
    props: true,
    meta: { requiresAuth: false },
  },
  {
    path: '/update-history/:id/:pandaId',
    name: 'UpdatePandaHistory',
    component: UpdatePandaHistory,
    props: true,
    meta: { requiresAuth: false },
  },
  {
    path: '/create-panda',
    name: 'CreatePanda',
    component: CreatePanda,
    meta: { requiresAuth: false },
  },
  {
    path: '/create-panda-relationships',
    name: 'CreatePandaRelationships',
    component: CreatePandaRelationships,
    meta: { requiresAuth: false },
  },
  {
    path: '/query-panda-relationships/:pageNum/:pageSize',
    name: 'QueryPandaRelationships',
    component: QueryPandaRelationships,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/update-panda-relationships/:relationshipsId',
    name: 'UpdatePandaRelationships',
    component: UpdatePandaRelationships,
    props: true,
    meta: { requiresAuth: false },
  },
  {
    path: '/query-approved/:pageNum/:pageSize',
    name: 'ApprovedPandas',
    component: ApprovedPandas,
    props: true,
    meta: { requiresAuth: false },
  },
  {
    path: '/update-panda/:pandaId',
    name: 'UpdatePanda',
    component: UpdatePanda,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/panda-profile/:pandaId',
    name: 'PandaProfile',
    component: PandaProfile,
    props: true,
    meta: { requiresAuth: false },
  },
  {
    path: '/query-panda/:pageNum/:pageSize',
    name: 'QueryPandas',
    component: QueryPandas,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/audit-panda/:pandaId',
    name: 'AuditTasksForPanda',
    component: AuditTasksForPanda,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/audit-history/:pandaId/:lifeId',
    name: 'AuditTasksForHistory',
    component: AuditTasksForHistory,
    props: true,
    meta: { requiresAuth: true },
  },
  // 你可以根据需要添加更多路由
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const username = localStorage.getItem('user_name');
    const token = localStorage.getItem('token');

    if (username && token) {
      try {
        const response = await getCheckUser(username, token);
        console.log(response);
        if (response.status === 200) {
          next();
        } else {
          // 如果状态码不是200，也重定向到登录页面
          next('/login');
        }
      } catch (error) {
        console.error('验证登录状态失败', error);
        if (error.response && error.response.status === 401) {
          // 明确处理401错误
          localStorage.removeItem('token');
          localStorage.removeItem('userId');
          next('/login');
        } else {
          // 其他错误，可能是网络问题等，按情况处理
          next('/login');
        }
      }
    } else {
      next('/login');
    }
  } else {
    next();
  }
});

export default router;


<template title="更新">
  <el-form :model="panda" label-width="auto" class="update-panda-form">
    <el-form-item label="姓名:" required>
      <el-input v-model="panda.name" placeholder="请输入熊猫姓名" :disabled="true"></el-input>
    </el-form-item>
    <el-form-item label="别名:" required>
      <el-input v-model="panda.alias" placeholder="请输入熊猫别名"></el-input>
    </el-form-item>
    <el-form-item label="年龄:" required>
      <el-input v-model="panda.age" :disabled="true"></el-input>
    </el-form-item>
    <el-form-item label="性别:" required>
      <el-select v-model="panda.sex" placeholder="请选择">
        <el-option label="未知" value="unknown"></el-option>
        <el-option label="雄性" value="M"></el-option>
        <el-option label="雌性" value="F"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="所在地:" required>
      <el-input v-model="panda.location" placeholder="请输入熊猫所在地"></el-input>
    </el-form-item>
    <el-form-item label="健康状态:" required>
      <el-input v-model="panda.healthStatus" placeholder="请输入健康状态"></el-input>
    </el-form-item>
    <el-form-item label="出生日期:" required>
      <el-date-picker v-model="panda.birthDate" type="date" format="YYYY-MM-DD" value-format="YYYY-MM-DD" placeholder="选择日期"></el-date-picker>
    </el-form-item>
    <el-form-item label="死亡日期:">
      <el-date-picker v-model="panda.deathDate" type="date" format="YYYY-MM-DD" value-format="YYYY-MM-DD" placeholder="可不选"></el-date-picker>
    </el-form-item>
    <div class="form-footer">
      <el-form-item>
        <el-button type="primary" @click="updatePandas">提交更新</el-button>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import {useRouter} from "vue-router";
import {getPandaById, updatePanda} from '@/api/pandas'
import {ElMessage} from "element-plus";
import {ref} from "vue";
import router from "@/router";
export default {
  setup() {
    const panda = ref({
      id: '',
      name: '',
      age: 0,
      location: '',
      sex: '',
      healthStatus: '',
      birthDate: '',
      deathDate: '',
    });

    // 假设我们通过路由参数获取熊猫ID
    const route = useRouter();
    const pandaId = route.currentRoute.value.params.pandaId;

    // 获取熊猫信息
    async function fetchPanda() {
      let response;
      try {
        console.log(pandaId)
        response = await getPandaById(pandaId);
        console.log(response.data.data)
        panda.value = response.data.data;
      } catch (error) {
        console.log(error)
        if (error.response && error.response.status === 400) {
          ElMessage.error(error.response.data.message);
        } else {
          ElMessage.error('查询失败');
        }
      }
    }

    fetchPanda();

    // 更新熊猫信息
    async function updatePandas() {
      // 可能还需要一些成功的提示或者跳转逻辑
      let response;
      try {
        response = await updatePanda(panda.value);
        console.log(response.data.message);
        //新增成功，跳转到熊猫档案信息页面
        router.push({
          name: 'QueryPandas' ,
          params: {
            pageNum: 1,
            pageSize: 10.
          }
        });
      } catch (error) {
        console.log(error)
        if (error.response && error.response.status === 400) {
          ElMessage.error(error.response.data.message)
        } else {
          ElMessage.error('更新失败，请重试。')
        }
      }
    }

    return {
      panda,
      updatePandas,
    };
  },
};
</script>
<style scoped>
.update-panda-form {
  max-width: 600px;
  margin: 20px auto auto;
}
.form-footer {
  display: flex;
  justify-content: flex-end;
}
/* 移动端样式调整 */
@media (max-width: 768px) {
  .update-panda-form {
    margin: 10px;
  }

  .el-form-item__label {
    flex-basis: auto; /* 允许标签自适应宽度 */
    text-align: left; /* 标签左对齐 */
  }

  .el-form-item {
    margin-bottom: 15px; /* 增加表单项之间的间隔 */
    display: flex; /* 使标签和输入框水平排列 */
    align-items: center; /* 保持项内容垂直居中 */
  }

  .el-form-item__content {
    flex-grow: 1; /* 输入框占据剩余空间 */
  }

  .form-footer {
    justify-content: center; /* 移动端将提交按钮居中显示 */
  }
}
</style>

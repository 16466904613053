import service from "@/api/axiosConfig";

export const getAllCountForPanda = () => {
    return service.get(`/pandas/v1/approvedPandas/total`);
};

export const getApprovedPandasByPage = (pageNum, pageSize) => {
    return service.get(`/pandas/v1/approvedPandas/page/${pageNum}/${pageSize}`);
};

export const getApprovedPandasByNameForPage = (pageNum, pageSize,name) => {
    return service.get(`/pandas/v1/approvedPandas/page/${pageNum}/${pageSize}?name=${name}`);
};

export const getApprovedPandasByPandaId = (pandaId) => {
    return service.get(`/pandas/v1/approvedPandas/${pandaId}`);
};


<template>
  <div ref="treeContainer" className="tree-container">
    <svg ref="svg"></svg>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  props: ['pandaFamilyTreeVo'],
  mounted() {
    this.prepareDataAndDrawTree(this.pandaFamilyTreeVo);
  },
  watch: {
    pandaFamilyTreeVo: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.prepareDataAndDrawTree(newVal);
      }
    }
  },
  methods: {
    prepareDataAndDrawTree(data) {
      if (data) {
        const transformedData = this.transformData(data);
        this.drawTree(transformedData);
      }
    },
    transformData(data) {
      const buildFamilyNode = (node) => {
        const children = [];

        // 添加父母节点
        if (node.parents) {
          if (node.parents.father) {
            const father = {...node.parents.father, relation: 'father'};
            children.push(buildFamilyNode(father));
          }
          if (node.parents.mather) {
            const mother = {...node.parents.mather, relation: 'mother'};
            children.push(buildFamilyNode(mother));
          }
        }

        // 添加子节点
        if (node.children) {
          node.children.forEach(child => {
            children.push(buildFamilyNode(child));
          });
        }

        // 添加配偶节点
        if (node.spouse) {
          node.spouse.forEach(sp => {
            const spNode = {...sp, relation: 'spouse'};
            children.push(buildFamilyNode(spNode));
          });
        }

        return {...node, children};
      };

      const rootNode = buildFamilyNode(data);

      return rootNode;
    },
    drawTree(data) {
      const margin = {top: 0, right: 90, bottom: 30, left: 260};
      const width = 960 - margin.left - margin.right;
      const height = 500 - margin.top - margin.bottom;

      const svg = d3.select(this.$refs.svg);
      svg.selectAll("*").remove(); // 清除之前的内容

      const g = svg.attr("width", width + margin.left + margin.right)
          .attr("height", height + -margin.top + margin.bottom)
          .append("g")
          .attr("transform", `translate(${margin.left},${margin.top})`);

      const treemap = d3.tree().nodeSize([80, 120]);

      const root = d3.hierarchy(data, d => d.children && d.children.length > 0 ? d.children : null);

      treemap(root);

      root.descendants().forEach(d => d.y = height - d.y);

      const actualWidth = Math.max(...root.descendants().map(d => d.x)) + margin.right;
      const actualHeight = Math.max(...root.descendants().map(d => d.y)) + margin.bottom;

      svg.attr("viewBox", [0, 0, actualWidth + margin.left, actualHeight + margin.top])
          .attr("preserveAspectRatio", "xMinYMin meet");

      g.selectAll(".link")
          .data(root.links())
          .enter()
          .append("path")
          .attr("class", "link")
          .attr("d", d => `
          M${d.source.x},${d.source.y}
          V${(d.source.y + d.target.y) / 2}
          H${d.target.x}
          V${d.target.y}
        `)
          .attr("stroke", "#999")
          .attr("stroke-width", 1.5)
          .attr("fill", "none");

      const node = g.selectAll(".node")
          .data(root.descendants())
          .enter()
          .append("g")
          .attr("class", "node")
          .attr("transform", d => `translate(${d.x},${d.y})`);

      node.append("circle")
          .attr("r", 10)
          .attr("fill", d => {
            if (d.data.sex === "M") return "#000"; // 黑色
            if (d.data.sex === "F") return "#ff0000"; // 红色
            return "#0000ff"; // 蓝色
          })
          .attr("stroke", "steelblue")
          .attr("stroke-width", 3);

      node.append("text")
          .attr("dy", "0.35em")
          .attr("x", d => d.children && d.children.length > 0 ? -13 : 13)
          .style("text-anchor", d => d.children && d.children.length > 0 ? "end" : "start")
          .text(d => d.data.name);

      this.addSiblings(g, data, root);
    },
    addSiblings(g, data, root) {
      const siblings = data.siblings || [];
      const siblingSpacing = 100;

      siblings.forEach((sibling, index) => {
        const xOffset = root.x + siblingSpacing * (index + 1);
        const yOffset = root.y;
        const siblingGroup = g.append("g")
            .attr("class", "node sibling")
            .attr("transform", `translate(${xOffset},${yOffset})`);

        siblingGroup.append("circle")
            .attr("r", 10)
            .attr("fill", sibling.sex === "M" ? "#000" : sibling.sex === "F" ? "#ff0000" : "#0000ff")
            .attr("stroke", "steelblue")
            .attr("stroke-width", 3);

        siblingGroup.append("text")
            .attr("dy", "0.35em")
            .attr("x", 13)
            .style("text-anchor", "start")
            .text(sibling.name);

        g.append("path")
            .attr("class", "link sibling-link")
            .attr("d", `
            M${root.x},${root.y}
            H${xOffset}
          `)
            .attr("stroke", "#999")
            .attr("stroke-width", 1.5)
            .attr("fill", "none");
      });
    }
  }
}
</script>

<style scoped>
.tree-container {
  width: 100%;
  height: 60%;
  overflow: auto;
  display: flex;
  justify-content: right; /* 水平居中 */
}

.tree-container svg {
  display: block;
  margin: -180px 0 0 -100px; /* 保证SVG在容器中居中 */
}


.link {
  fill: none;
  stroke: #999;
  stroke-width: 1.5px;
}

.node circle {
  stroke: steelblue;
  stroke-width: 3px;
}

.node text {
  font: 12px sans-serif;
}
</style>
